<template>
    <div class="app_container">
        <div class="week_bg_2 margin-top" style="background-image: url(img/weekParty/top_bg_2.png);">
            <div class="go_back_button" style="background-image: url(img/weekParty/go_back.png);" @click="goBack"></div>

            <!-- <div class="st_share_button" style="background-image: url(img/share_button.png);" @click="goShare"></div> -->
            <div class="main_box">
                <div class="active_title4"
                    style="background-image: url(img/weekParty/lottery_record.png);margin-top: 0;"></div>
                <div class="slide_list2" style="background-image: url(img/weekParty/rank_bg_2.png);overflow: hidden;">
                    <div class="warp" style="overflow-y: scroll;">
                        <div class="w_noData" v-if="noData">
                            <img src="img/weekParty/no_data.png" alt="">
                        </div>
                        <div class="gift_item_record"
                            :style="item.rank == '3' ? 'background-image: url(img/weekParty/level_three.png);' : (item.rank == '2' ? 'background-image: url(img/weekParty/level_two.png);' : 'background-image: url(img/weekParty/level_one.png);')"
                            v-for="(item, index) in listData" :key="index">
                            <div class="gift_item_left">
                                <div class="record_pic" :style="'background-image: url(' + item.gift_url + ');'">
                                </div>
                            </div>
                            <div class="gift_item_right">
                                <span class="gift_name"
                                    style="margin-top:10px;text-align: right;">{{ item.gift_name == '随机金币' ? (item.gift_name + item.diamonds + 'X' + item.num) : item.gift_name + 'X' + item.num }}</span>
                                <span class="gift_date"
                                    style="margin-top:10px;text-align: right;">{{ item.created_at }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom_bg" style="background-image: url(img/weekParty/bottom_text.png);"></div>
            </div>

        </div>

        <!-- <div class="mask" v-if="showMask">
        <div class="gift_box_share" style="background-image: url(img/share_bg.png);">
          <div class="btn-container">
            <div class="wechat" style="background-image: url(img/wechat_bg.png);" @click="shareToWechat"></div>
            <div class="firend_circle" style="background-image: url(img/firend_circle.png);" @click="shareToTimeline">
            </div>
          </div>
          <div class="ok_btn" style="background-image: url(img/ok.png);" @click="closeMask"></div>
        </div>
      </div> -->
    </div>
</template>
     
<script>
import "@/assets/css/weekLuckyMan.css"
import { weekPartyRecord } from "@/api/ranking"
export default {
    name: "lotteryRecord",
    data() {
        return {
            noData: true,
            listData: []
        };
    },

    components: {

    },
    created() {
        this.getRecord()
    },

    watch: {},

    mounted() { },

    methods: {

        getRecord() {
            weekPartyRecord({}).then(response => {
                if (response.data.length == 0) {
                    this.noData = true
                } else {
                    this.noData = false
                    this.listData = response.data
                }
            },
                error => {
                    this.noData = true
                    // this.$toast.fail(error.msg)
                }).catch((error) => {
                    this.noData = true
                    // this.$toast.fail(error.msg)
                })
        },
        goBack() {
            this.$router.go(-1)
        }
    },

    computed: {}
};
</script>
     
     